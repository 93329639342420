<template>
    <div class="whole">
        <van-icon name="checked" color="#4C94F6" size="40px" />
        <p>验证成功</p>
        <p style="width: 80%;margin: 0 auto;">
            你的账号已验证成功，你现在可以使用微信扫码登录智工邦业务管理平台
        </p>
    </div>
</template>

<script>
import { Icon } from 'vant';
export default {
    components: {
        [Icon.name]: Icon,
    },
    created() {
        this.$store.commit("hideTabbar");
    }
}
</script>

<style scoped>
p {
    font-size: 28px;
}

.whole {
    text-align: center;
    padding-top: 30px;
    margin-top: 50px;
}
</style>
